<template lang="html">
  <div class="custom-amount">
    <div class="custom-amount__image" v-if="product.picture">
      <ImageInContainer
        :src="product.picture.sizes.medium_large"
        :alt="product.picture.alt"
        :scale="`${(217 / 368) * 100}%`"
      />
    </div>
    <div class="custom-amount__content">
      <div class="custom-amount__heading card-title" v-if="product.title">
        <Heading :text="product.title" :hType="3" />
      </div>

      <div
        class="custom-amount__description"
        v-if="product.description"
        v-html="product.description"
      ></div>

      <div class="custom-amount__range">
        <RangeInput :min="0" :max="1000" units="€" @onSlide="onSlide" />
      </div>

      <div
        class="custom-amount__clouds"
        v-if="product.categories && product.categories.length"
      >
        <CloudsSelect
          type="radio"
          :items="product.categories"
          @change-value="selectCategories"
        />
      </div>

      <div class="custom-amount__button">
        <!--        <div class="product-card__price">{{ customValue }} €</div>-->
        <Button :disabled="!Number(customValue)" @click="showDonationForm">
          <span v-if="globalSettings">
            {{ globalSettings['button_names']['donate_now'] }}
          </span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInContainer from '@/components/ImageInContainer/ImageInContainer.vue';
import RangeInput from '@/components/RangeInput/RangeInput.vue';
import CloudsSelect from '@/components/CloudsSelect/CloudsSelect.vue';
import Button from '@/components/Button/Button.vue';
import Heading from '@/components/Heading/Heading.vue';

export default {
  name: 'CustomAmount',
  components: {
    RangeInput,
    CloudsSelect,
    Button,
    Heading,
    ImageInContainer,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customValue: 0,
    };
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
  methods: {
    selectCategories(categories) {
      this.$store.commit('donationProductForm/setSelectedCategory', categories);
    },
    showDonationForm() {
      if (!this.customValue) return;
      this.$store.commit('donationProductForm/setFormStepsIsShow', true);
      this.$store.commit('donationProductForm/setValue', this.customValue);
      this.$store.commit('donationProducts/setCurrentProduct', this.product);

      this.$store.commit('donationPayment/setPostId', this.product.ID);
      this.$store.commit('donationPayment/setAmount', this.customValue);
    },
    onSlide(val) {
      this.customValue = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
