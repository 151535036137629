<template lang="html">
  <div class="product-card">
    <div class="product-card__image" v-if="product.picture">
      <ImageInContainer
        :src="product.picture.sizes.medium_large"
        :alt="product.picture.alt"
        :scale="`${(217 / 368) * 100}%`"
      />
    </div>
    <div class="product-card__content">
      <div class="product-card__heading card-title" v-if="product.title">
        <Heading :text="product.title" :hType="3" />
      </div>
      <div class="product-card__middle-content">
        <div
          class="product-card__description"
          v-if="product.description"
          v-html="product.description"
        ></div>
        <div
          class="product-card__price"
          v-if="product.price && !isSeveralAmounts"
        >
          {{ product.price }} €
        </div>
      </div>
      <div class="product-card__button">
        <div v-if="isSeveralAmounts">
          <Select
            v-model="priceValue"
            :options="prices"
            :default="prices[0]"
            @show-dropdown="showDropdown"
          />
        </div>
        <div class="product-card__price" v-else-if="product.price">
          {{ product.price }} €
        </div>
        <Button>
          <span v-if="globalSettings" @click="showDonationForm">{{
            globalSettings['button_names']['choose']
          }}</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInContainer from '@/components/ImageInContainer/ImageInContainer.vue';
import Heading from '@/components/Heading/Heading.vue';
import Button from '@/components/Button/Button.vue';
import Select from '@/components/Select/Select.vue';

export default {
  name: 'Header',
  components: {
    ImageInContainer,
    Heading,
    Button,
    Select,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      priceValue: null,
    };
  },
  computed: {
    isSeveralAmounts() {
      return this.product.several_amounts === 'yes';
    },
    globalSettings() {
      return this.$store.state.global.settings;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
    prices() {
      return this.product.prices
        .filter(({ price }) => price)
        .map(({ price }) => ({
          value: price,
          label: `${price} €`,
        }));
    },
    priceToForm() {
      return !this.isSeveralAmounts
        ? this.product.price
        : this.priceValue.value;
    },
  },
  methods: {
    showDropdown(e) {
      this.$emit('show-dropdown', e);
    },
    showDonationForm() {
      this.$store.commit('donationProductForm/setFormStepsIsShow', true);
      this.$store.commit('donationProductForm/setValue', this.priceToForm);
      this.$store.commit('donationProducts/setCurrentProduct', this.product);

      this.$store.commit('donationPayment/setPostId', this.product.ID);
      this.$store.commit('donationPayment/setAmount', this.priceToForm);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
