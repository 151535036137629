<template lang="html">
  <div
    class="donation-products gray-background"
    v-if="!$store.state.donationProductForm.formStepsIsShow || !isMobile"
  >
    <div
      class="donation-products__item"
      v-for="product in $store.state.donationProducts.donationProducts"
      :key="product.ID"
      :class="{ active: activeCards[product.ID] }"
    >
      <ProductCard
        v-if="product.custom_amount.value === 'no'"
        :product="product"
        @show-dropdown="showDropdown($event, product.ID)"
      />
      <CustomAmount
        v-else-if="product.custom_amount.value === 'yes'"
        :product="product"
      />
    </div>
  </div>
  <Sidebar
    :isShow="$store.state.donationProductForm.formStepsIsShow"
    @closeSidebar="closeSidebar"
  >
    <DonationForm />
  </Sidebar>
</template>

<script>
import ProductCard from '@/components/ProductCard/ProductCard.vue';
import CustomAmount from '@/components/CustomAmount/CustomAmount.vue';
import DonationForm from '@/sections/DonationForm/DonationForm.vue';
import Sidebar from '@/components/Sidebar/Sidebar.vue';
import { calculateHeightTitles } from '@/utils';

export default {
  name: 'DonationProducts',
  components: { ProductCard, CustomAmount, DonationForm, Sidebar },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeCards: {},
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.global.isMobile;
    },
  },
  created() {
    this.$store.dispatch('donationProducts/fetchDonationProducts');
  },
  mounted() {
    window.addEventListener('resize', this.calculateHeightTitles);
  },
  beforeUnmount() {
    this.$store.commit('donationProducts/setDonationProducts', []);
    window.removeEventListener('resize', this.calculateHeightTitles);
  },
  methods: {
    showDropdown(e, id) {
      if (e) {
        this.activeCards[id] = id;
      } else {
        setTimeout(() => {
          this.activeCards[id] = e;
        }, 100);
      }
    },
    calculateHeightTitles() {
      this.$nextTick(() => {
        calculateHeightTitles();
      });
    },
    closeSidebar() {
      this.$store.dispatch('donationProductForm/reset');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>

<style lang="scss">
.donation-products__item.active {
  z-index: 100;
}
</style>
