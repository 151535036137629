<template lang="html">
  <div class="clouds-select">
    <div class="clouds-select__heading">Was wollen Sie unterstützen?</div>
    <div class="clouds-select__items">
      <div
        class="clouds-select__item"
        v-for="(item, index) in items"
        :key="index"
      >
        <label>
          <input :type="type" :value="item.term_id" v-model="checkedItems" />
          <span>{{ item.name }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloudsSelect',

  props: {
    type: {
      type: String,
      required: false,
      default: 'checkbox',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      checkedItems: [],
    };
  },
  watch: {
    checkedItems(val) {
      this.$emit('change-value', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
